.ProfileImg {
  min-width: 200px;
  min-height: 200px;
  max-width: 200px;
  max-height: 200px;
  border-radius: 50%;
  overflow: hidden;
  border: 4px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ProfileImg img {
  opacity: 90%;

}
