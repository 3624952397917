.project-button {
    background-color: rgb(255, 255, 255, 0.5);
    transition: all 0.2s ease-in-out;
    border: none;
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
}

.project-button:hover {
    cursor: pointer;
    background-color: rgb(255, 255, 255, 0.8);
}