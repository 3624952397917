.Nav {
    min-height: 80px;
    background-color: rgb(255, 255, 255, 0.4);
    flex: 1;
}

.Nav button {
    background-color: rgb(255, 255, 255, 0.5);
    transition: all 0.2s ease-in-out;
    list-style: none;
    border: none;
}

.Nav button:hover {
    cursor: pointer;
    background-color: rgb(255, 255, 255, 0.8);
}